export const getSevaFormConfig = config => {
    config = config || {}
    return [{
        section: 'Seva Details',
        fields: [
            {
                title: "Seva ID",
                name: "id",
                mandatory: true,
                post: v => v && v.trim()
            },
            {
                title: "Seva Name",
                name: "name",
                mandatory: true,
                post: v => v && v.toNameCase()
            },
            {
                title: "Seva Type",
                name: "type",
                mandatory: true,
                type: 'select',
                options: [
                    {
                        name: "Festival",
                        value: "festival"
                    }
                ]
            },
            {
                title: "Visibility",
                name: "visibility",
                mandatory: true,
                type: 'select',
                options: [
                    {
                        name: "Public",
                        value: "public"
                    },
                    {
                        name: "Unlisted",
                        value: "unlisted"
                    },
                    {
                        name: "Private",
                        value: "private"
                    },
                ]
            },
            {
                title: "Last Date for Registration",
                name: "lastDate",
                type: 'date',
                mandatory: true,
            },
            {
                title: "Is service list ready?",
                name: "isServiceListReady",
                type: "bool",
                empty: [false],
                mandatory: true,
                condition: () => config.isEdit
            },
            {
                title: "First date of seva",
                name: "startDate",
                type: "date",
                mandatory: true,
                condition: () => config.isDuplicate
            },
        ]
    }]
}

export const reverseSevaPayload = payload => {
    var data = { ...payload }
    if (data.isServiceListReady !== undefined) {
        data.isServiceListReady = !!data.isServiceListReady
    }
    return data
}