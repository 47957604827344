import "./index.css"
import { useGlobal } from "../../../../context/global"
import { useEffect, useState } from "react"
import PageLoader from "../../../../components/info/pageloader"
import Seva from "./seva"
import ApprovalPendingPage from "./applicant"

const VolunteerHome = () => {

    const { auth, api } = useGlobal()
    const { user } = auth

    var [sevaData, setSevaData] = useState()
    var [approval, setApproval] = useState()

    useEffect(() => {
        api.call('/get-volunteer-home-data', user).then(({ data }) => {
            setApproval(data.approval)
            setSevaData(data.sevaData)
        })
    }, [])

    return (
        sevaData !== undefined ?
            approval == 'approved' || approval == 'admin-pending' ?
                <div className="volhome-main flex-col">
                    <div className="volhome-feed flex-col">
                        <div className="volhome-upcoming">Upcoming Sevas</div>
                        {sevaData.length ?
                            <div className="felx-col">{sevaData.map(seva => <Seva seva={seva} />)}</div> :
                            <div>No upcoming sevas found!</div>}
                    </div>
                </div> : <ApprovalPendingPage />
            : <PageLoader text={"Loading..."} />
    )
}

export default VolunteerHome