import { useLocation } from "react-router-dom"
import "./index.css"
import Header from "../../components/custom/header"
import Form from "../../components/custom/form"
import { useEffect, useState } from "react"
import { useGlobal } from "../../context/global"
import PageLoader from "../../components/info/pageloader"
import { getRegistrationFormConfig } from "../../data/registration"

const Register = props => {

    const location = useLocation()
    const seva = location.state
    const { api, auth } = useGlobal()

    var [sevaDates, setSevaDates] = useState()
    var [registrations, setRegistrations] = useState()

    useEffect(() => {
        api.call("/get-seva-dates", { sevaID: seva.id }).then(res => {
            setSevaDates(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        api.call("/get-registration", { sevaID: seva.id }).then(res => {
            var temp = {}
            res.data.forEach(v => {
                temp[v.date] = JSON.parse(v.slots)
            })
            setRegistrations(temp)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleRegisterSubmit = (formData) => {

        const payload = Object.keys(formData).filter(date => formData[date] && formData[date].length).map(date => {
            return {
                date,
                sevaID: seva.id,
                volunteerID: auth.user.id,
                slots: JSON.stringify(formData[date])
            }
        })

        api.call('/add-registration', payload).then(() => {
            window.open("/home", "_self")
        }).catch(err => {
            console.log(err)
        })
    }

    const handleChangeSubmit = (_, formChange) => {

        const payload = Object.keys(formChange).map(date => {
            return {
                date,
                sevaID: seva.id,
                volunteerID: auth.user.id,
                slots: JSON.stringify(formChange[date])
            }
        })

        api.call('/update-registration', payload).then(() => {
            window.open("/home", "_self")
        }).catch(err => {
            console.log(err)
        })
    }

    return <div className="reg-main flex-col">
        <Header title={`${seva.name} Registration`} />
        <div className="flex-col">
            <div className="reg-inst1">Hare Krishna. Volunteering is required during below mentioned dates. Please tick ✓ the slots below for which you are available for volunteer services.</div>
            <div className="flex-col reg-inst1">
                <div className="reg-points bold" >Note:</div>
                <div className="reg-points">• Please abide by the time slot commitments you are making</div>
                <div className="reg-points">• Service timings may vary from your committed timings by a small margin</div>
            </div>
        </div>

        {(sevaDates && registrations) ? <div className="reg-form">
            <Form
                config={getRegistrationFormConfig(sevaDates)}
                name={`${seva.id}-registration-${auth.user.id}`}
                onSubmit={seva.hasRegistered ? handleChangeSubmit : handleRegisterSubmit}
                persist={!seva.hasRegistered}
                updateMode={seva.hasRegistered}
                def={seva.hasRegistered ? registrations : undefined}
            ></Form>
        </div> : <PageLoader text="Loading..." />}
    </div>
}

export default Register