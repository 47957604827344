import "./index.css"
import Icon from "../../icon"
import { useNavigate } from "react-router-dom"
import { useGlobal } from '../../../../context/global'
import Glass from "../../glass"


const AppMenu = ({ open, close }) => {

    const { auth } = useGlobal()
    const { user } = auth

    const navigate = useNavigate()

    const menus = [
        {
            name: "Home",
            link: "/home",
            icon: "home",
            access: 9
        },
        {
            name: "Users",
            link: "/users",
            icon: "group",
            access: 0
        },
        {
            name: 'Applicants',
            link: "/applicants",
            icon: 'description',
            access: 3
        },
        {
            name: 'Volunteers',
            link: "/volunteers",
            icon: 'diversity3',
            access: 3
        },
        {
            name: 'Seva',
            link: "/admin/seva",
            icon: 'folded-hands',
            access: 0
        }
    ]

    const options = [
        {
            name: "Contact",
            link: "https://wa.me/916364903725",
            icon: "whatsapp",
            access: 9
        },
        {
            name: "Share",
            link: `https://wa.me?text=${encodeURI("Hare Krishna.\nISKCON Mysore Volunteering App:\nhttps://sevabase.iskconmysore.org")}`,
            icon: "share",
            access: 9
        },
        {
            name: "Sign Up",
            link: `/signup`,
            icon: "person-add",
            self: true,
            access: 9
        }
    ]

    return (
        <div>
            <div className={`appmenu-slider ${open ? "open" : ""}`}>
                <div className="appmenu-label">MENU</div>
                <div className="appmenu-1">
                    <div className="appmenu-items">
                        {
                            menus.filter(m => {
                                return m.exact ? m.access == user.roleIndex : m.access >= user.roleIndex
                            }).map(m => {
                                const handleMenuClick = () => {
                                    navigate(m.link)
                                }
                                return (
                                    <div className={`appmenu-item ${window.location.pathname == m.link ? "selected" : ""}`} onClick={handleMenuClick}>
                                        <Icon name={m.icon} color="#fff" size={[5, 2]} />
                                        <div className="appmenu-item-name">{m.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="appmenu-items">
                        {
                            options.filter(m => {
                                return m.exact ? m.access == user.roleIndex : m.access >= user.roleIndex
                            }).map(m => {
                                const handleMenuClick = () => {
                                    window.open(m.link, m.self ? "_self" : "_blank")
                                }
                                return (
                                    <div className={`appmenu-item`} onClick={handleMenuClick}>
                                        <Icon name={m.icon} color="#fff" size={[5, 2]} />
                                        <div className="appmenu-item-name">{m.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="appmenu-copy">© ISKCON Mysore</div>
            </div>
            {open ? <Glass onClick={close} /> : null}
        </div>
    )
}

export default AppMenu