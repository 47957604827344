import "./index.css"
import Icon from "../../../../components/custom/icon"
import _ from "../../../../_"
import moment from "moment"
import ContextMenu from "../../../../components/holders/contextmenu"
import { useState } from "react"
import Form from "../../../../components/custom/form"
import Modal from "../../../../components/holders/modal"
import { getSevaFormConfig, reverseSevaPayload } from "../../../../data/seva"
import { useGlobal } from "../../../../context/global"
import { useNavigate } from "react-router-dom"

const Seva = props => {

    const navigate = useNavigate()

    const { seva } = props
    const { api } = useGlobal()

    var [showContextMenu, setShowContextMenu] = useState()
    var [showEdit, setShowEdit] = useState()
    var [showDuplicate, setShowDuplicate] = useState()

    const handleEdit = () => {
        setShowEdit(true)
    }

    const handleDuplicate = () => {
        setShowDuplicate(true)
    }

    const closeModal = () => {
        setShowEdit(false)
        setShowDuplicate(false)
    }

    const handleEditSubmit = (_, formChanges) => {
        api.call("/edit-seva", { oldID: seva.id, ...formChanges }).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleDuplicateSubmit = formData => {
        api.call("/duplicate-seva", { newSeva: formData, oldSeva: seva }).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleDelete = () => {
        api.call("/delete-seva", seva).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const contextMenuConfig = [
        {
            name: 'Edit',
            onClick: handleEdit
        },
        {
            name: 'Duplicate',
            onClick: handleDuplicate
        },
        {
            name: 'Delete',
            onClick: () => {
                window.confirm(`Are you sure you want to delete this seva: ${seva.name}?`) && handleDelete()
            },
            className: 'seva-del'
        }
    ]

    const gotoDates = () => {
        navigate("/admin/seva-dates", { state: seva })
    }

    return <div className="seva-main flex-col">
        {
            (showEdit || showDuplicate) && <Modal close={closeModal} title={`${showEdit ? "Edit" : "Duplicate"} Seva`}>
                <div className="sevas-create-modal">
                    <Form
                        config={getSevaFormConfig({ isEdit: showEdit, isDuplicate: showDuplicate })}
                        def={reverseSevaPayload(seva)}
                        name={`seva-${showEdit ? 'edit' : 'duplicate'}`}
                        updateMode={showEdit}
                        onSubmit={showEdit ? handleEditSubmit : handleDuplicateSubmit}
                    ></Form>
                </div>
            </Modal>
        }


        <div className="flex-row flex-space-between flex-align-center">
            <div className="flex-col" onClick={gotoDates}>
                <div className="seva-name">{seva.name}</div>
                <div className="seva-date">{seva.mainDate ? moment(seva.mainDate).format("Do MMM YYYY") : "No date mentioned"}</div>
            </div>
            <div>
                <Icon name="more-horiz" color="#aaa" size={6} onClick={() => { setShowContextMenu(true) }} />
                {showContextMenu && <ContextMenu close={() => { setShowContextMenu(false) }} orientation={'left'} config={contextMenuConfig} />}
            </div>
        </div>
        <div style={{ marginTop: "2vw" }} />
        <div className="flex-row flex-space-between flex-end">
            <div className="flex-row">
                <div>
                    <Icon name={_.getVisibilityIcon(seva.visibility)} size={4.25} color="#555" />
                </div>
                <div style={{ marginRight: "0.75vw" }} />
                <div>{seva.type.toTitleCase()}</div>
            </div>
            <div className="seva-createdOn">{moment(seva.createdOn, "YYYY-MM-DD HH:mm").format("Do MMM YYYY")}</div>
        </div>
    </div>
}

export default Seva