import "./index.css"
import { useGlobal } from '../context/global'
import Header from '../components/custom/header'
import Icon from '../components/custom/icon'


const Protect = ({ role, children }) => {

    const { auth } = useGlobal()
    const { user } = auth

    return (
        user.roleIndex <= role ?
            children :
            <div className='protect-main'>
                <Header title={'Not allowed!'} />
                <div className='protect-cont'>
                    <Icon name="block" size={[30, 15]} color="#555" />
                    <div className='protect-text'>You are not allowed to view this page!</div>
                </div>
            </div>

    )
}

export default Protect

