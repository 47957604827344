import "./index.css"
import moment from 'moment'
import { useNavigate } from "react-router-dom"

const Seva = props => {

    const { seva } = props
    const { name, date, lastDate, hasRegistered } = seva

    const navigate = useNavigate()

    const handleRegister = () => {
        navigate("/register", { state: seva })
    }

    return <div className="flex-col volseva-main">
        <div className="volseva-cont">
            <div className="flex-row flex-space-between volseva-2">
                <div className="flex-col volseva-1">
                    <div className="bold volseva-name">{name}</div>
                    <div className="">{moment(date).format("dddd, Do MMM YYYY")}</div>
                </div>
                <button onClick={handleRegister} className="volseva-reg">{hasRegistered ? 'Modify' : 'Register Now'}</button>
            </div>
            <div className="volseva-info">{hasRegistered ? 'You have registered for this seva' : `Last date for registration: ${moment(lastDate).format("Do MMM YYYY")}`}</div>
        </div>
        <hr style={{opacity: 0}}/>
    </div>
}

export default Seva