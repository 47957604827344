// src/firebase-config.js

import { initializeApp } from "firebase/app"

// Your Firebase configuration from the SDK
const firebaseConfig = {
  apiKey: "AIzaSyCKZ-V8KQ8aGyGrWgPAY3mEwNjXodAQX50",
  authDomain: "pushnotif-982e1.firebaseapp.com",
  projectId: "pushnotif-982e1",
  storageBucket: "pushnotif-982e1.firebasestorage.app",
  messagingSenderId: "791639354356",
  appId: "1:791639354356:web:de4f30d3f0496b039367a1",
  measurementId: "G-NW3TE98QTF"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export default app
