import './index.css'

const Spinner = ({ style, text, size, className, black }) => {
  size = size || 4
  return (
    <div className={`spinner-main ${className}`}>
      <div className={`spinner-bg-circle`}>
      </div>
      {text ? <div className={`spinner-load-text ${black ? 'black' : ''}`}>{text}</div> : null}
    </div>
  );
}

export default Spinner;
