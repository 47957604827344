import React, { useState } from 'react'
import "./index.css"
import Header from '../../components/custom/header'
import { useGlobal } from '../../context/global'
import VolunteerHome from './homes/volunteer'
import ConstructionHome from './homes/construction'

function Home() {
  const { auth } = useGlobal()
  const { user } = auth || {}

  const HomeContent = () => {
    switch (user.roleID) {
      case "volunteer":
        return <VolunteerHome />
      default:
        return <ConstructionHome />
    }
  }

  return (
    <div>
      <Header title={'SevaBase'} />
      <div className='home-main'>
        <HomeContent />
      </div>
    </div>
  )
}


export default Home
