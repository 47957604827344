import "./index.css"
import Icon from "../../../../components/custom/icon"
import moment from 'moment'
import { useState } from "react"
import ContextMenu from "../../../../components/holders/contextmenu"
import { useGlobal } from "../../../../context/global"
import Modal from "../../../../components/holders/modal"
import Form from "../../../../components/custom/form"
import { getSevaDateFormConfig, getSevaDatePayload, reverseSevaDatePayload } from "../../../../data/sevadate"

const SevaDate = props => {

    const { api } = useGlobal()

    const { sevadate, seva } = props
    var [showContextMenu, setShowContextMenu] = useState()

    var [showEdit, setShowEdit] = useState()
    var [showDuplicate, setShowDuplicate] = useState()

    const closeModal = () => {
        setShowDuplicate(false)
        setShowEdit(false)
    }

    const contextMenuConfig = [
        {
            name: 'Edit',
            onClick: () => { setShowEdit(true) }
        },
        {
            name: 'Duplicate',
            onClick: () => { setShowDuplicate(true) }
        },
        {
            name: 'Delete',
            onClick: () => {
                window.confirm(`Are you sure you want to delete this seva date: ${sevadate.date}?`) && handleDelete()
            },
            className: 'seva-del'
        }
    ]

    const handleEditSubmit = (_, formChanges) => {
        api.call("/edit-seva-date", { oldDate: sevadate.date, sevaID: seva.id, ...getSevaDatePayload(formChanges) }).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleDuplicateSubmit = (formData) => {
        const payload = { ...getSevaDatePayload(formData), sevaID: seva.id }
        api.call("/add-seva-date", payload).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleDelete = () => {
        api.call("/delete-seva-date", sevadate).then(() => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    return <div className="flex-col sevadate-main">

        {
            (showEdit || showDuplicate) && <Modal close={closeModal} title={`${showEdit ? 'Edit' : 'Duplicate'} Seva Date`}>
                <div className="sevadate-create-modal">
                    <Form
                        config={getSevaDateFormConfig({ isEdit: true })}
                        def={reverseSevaDatePayload(sevadate)}
                        updateMode={showEdit}
                        onSubmit={showEdit ? handleEditSubmit : handleDuplicateSubmit}
                    ></Form>
                </div>
            </Modal>
        }

        <div className="flex-row flex-space-between">
            <div className="flex-col">
                <div className="bold">{moment(sevadate.date).format('Do MMM YYYY')}</div>
                <div className="light">{`${sevadate.description}${sevadate.isMain ? " *" : ""}`}</div>
            </div>
            <div>
                <Icon name="more-horiz" onClick={() => { setShowContextMenu(true) }} />
                {showContextMenu && <ContextMenu close={() => { setShowContextMenu(false) }} orientation={'left'} config={contextMenuConfig} />}
            </div>
        </div>
        <div style={{ marginTop: "2vw" }} />
        <div className="flex-row flex-end">
            <div className="sevadate-icon"><Icon name={sevadate.isHidden ? "hidden" : "visible"} color="#555" size={5} /></div>
            <div className="sevadate-icon"><Icon name={sevadate.hasBadge ? "account-circle" : "account-circle-off"} color="#555" size={5} /></div>
            <div className="sevadate-icon"><Icon name={sevadate.hasCoupon ? "card-membership" : "comments-disabled"} color="#555" size={5} /></div>
        </div>
    </div>
}

export default SevaDate