export const getSevaDateFormConfig = () => {
    return [{
        section: 'Seva Date',
        fields: [
            {
                title: "Enter Date",
                name: "date",
                mandatory: true,
                type: "date"

            },
            {
                title: "Description",
                name: "description",
                mandatory: true,
            },
            {
                title: "Is this a main date?",
                name: "isMain",
                type: "bool",
                empty: [false],
                mandatory: true
            },
            {
                title: "Is badge required?",
                name: "hasBadge",
                type: "bool",
                empty: [false],
                mandatory: true
            },
            {
                title: "Is coupon required?",
                name: "hasCoupon",
                type: "bool",
                empty: [false],
                mandatory: true
            },
            {
                title: "Is this hidden?",
                name: "isHidden",
                type: "bool",
                empty: [false],
                mandatory: true
            },
            {
                title: "Slots",
                name: "slots",
                type: 'para',
                mandatory: true,
                post: v => v.toUpperCase().split("\n").map(v => v.trim().split("-").map(t => t.trim()).join(" - ").replace(/\s+/g, ' ')).join("\n")
            }
        ]
    }]
}


export const getSevaDatePayload = formData => {
    const payload = { ...formData }
    payload.slots = payload.slots && JSON.stringify(payload.slots.split("\n"))
    return payload
}

export const reverseSevaDatePayload = payload => {
    const data = { ...payload }
    data.slots = JSON.parse(payload.slots).join("\n")
    if (data.isMain !== undefined) { data.isMain = !!data.isMain }
    if (data.hasCoupon !== undefined) { data.hasCoupon = !!data.hasCoupon }
    if (data.isHidden !== undefined) { data.isHidden = !!data.isHidden }
    if (data.hasBadge !== undefined) { data.hasBadge = !!data.hasBadge }
    return data
}