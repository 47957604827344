const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^\d{10}$/

const coerceToArray = e => Array.isArray(e)?e:[e]

const filterAccess = (a, u) => a.filter(b => (b.exact || Array.isArray(b.access))?coerceToArray(b.access).indexOf(u.roleIndex)!=-1:u.roleIndex<=(b.access||99999))

const getVisibilityIcon = visibility => {
    const vmap = {
        public: "public",
        unlisted: "link",
        private: "lock",
    }

    return vmap[visibility]
}

const _ = {
    emailRegex,
    phoneRegex,
    coerceToArray,
    filterAccess,
    getVisibilityIcon
}

export default _