import "./index.css"
import Icon from "../../../../../components/custom/icon"

const ApplicantHome = () => {
    return (
        <div className="flex-col apphome-main">
            <Icon name="timer" size={[25, 10]} color="#555" />
            <div className='apphome-text flex-col flex-align-center'>
                <div className="flex-col flex-align-center apphome-thanks-cont">
                    <b className="apphome-thanks">Hare Krishna!</b>
                    <b className="apphome-thanks">Thank you for signing up on SevaBase!</b>
                </div>

                <div className="apphome-wait">{`Your application is currently under review, and our team will approve it shortly. We kindly request your patience during this process. Once approved, you will be able to view seva opportunities directly on the homepage.`}</div>
                <div className="apphome-query">For any queries contact us on WhatsApp</div>
                <a className="apphome-send" href="https://wa.me/916364903725" target="_blank">Send Message</a>

            </div>
        </div>
    )
}

export default ApplicantHome