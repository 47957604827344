import { useCallback, useEffect, useState } from "react"
import Icon from "../../../icon"
import "./index.css"

const MCQ = props => {

    const { options, def, onFocusChange, onEditComplete, post } = props

    var [checked, setChecked] = useState(def || [])
    var [focus, setFocus] = useState()

    const handleKeyDown = (e, option) => {
        if (e.key == 'Enter') {
            handleCheck(option)
        }
    }

    useEffect(() => {
        if (focus !== undefined) {
            onFocusChange && onFocusChange(focus)
        }
    }, [focus])

    const handleCheck = useCallback(option => {
        const optionIndex = checked.indexOf(option)
        var temp = [...checked]
        if (optionIndex != -1) {
            temp.splice(optionIndex, 1)
        } else {
            temp.push(option)
        }

        if (post) {
            temp = post(temp)
        }

        setChecked(temp)
        onEditComplete && onEditComplete(temp)
    }, [checked])

    const handleFocus = () => { setFocus(true) }
    const handleBlur = () => { setFocus(false) }

    return (
        <div className="flex-col" tabIndex={0} onFocus={handleFocus} onBlur={handleBlur}>
            {
                options.map(option => {

                    const value = typeof (option) == 'object' ? option.value : option
                    const disabled = option.disabled && option.disabled(checked)

                    return <div tabIndex={0} onKeyDown={e => { !disabled && handleKeyDown(e, value) }} className="flex-row flex-start flex-align-center" onClick={() => { !disabled && handleCheck(value) }}>
                        <Icon name={checked.indexOf(value) != -1 ? "checkbox" : "uncheckbox"} color={disabled ? '#aaa' : '#888'} />
                        <div style={{ marginRight: "1vw" }} />
                        <div style={{ color: disabled ? '#aaa' : '#000' }}>{value}</div>
                    </div>
                })
            }
        </div>
    )
}

export default MCQ