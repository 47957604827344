import "./index.css"
import Header from "../../../components/custom/header"
import Icon from "../../../components/custom/icon"
import { useEffect, useState } from "react"
import Modal from "../../../components/holders/modal"
import _ from "../../../_"
import Form from "../../../components/custom/form"
import { useGlobal } from "../../../context/global"
import PageLoader from "../../../components/info/pageloader"
import { getSevaDateFormConfig, getSevaDatePayload } from "../../../data/sevadate"
import { useLocation } from "react-router-dom"
import ListView from "../../../components/holders/listView"
import SevaDate from "./sevadate"

const SevaDates = props => {

    const location = useLocation()
    const seva = location.state

    const { api } = useGlobal()

    var [showAdd, setShowAdd] = useState()
    var [sevaDates, setSevaDates] = useState()

    const handleAdd = () => {
        setShowAdd(true)
    }

    const closeModal = () => {
        setShowAdd(false)
    }

    useEffect(() => {
        api.call("/get-seva-dates", { sevaID: seva.id }).then(res => {
            setSevaDates(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])


    const handleSubmit = (formData, _, resetForm) => {
        const payload = { ...getSevaDatePayload(formData), sevaID: seva.id }
        api.call("/add-seva-date", payload).then(res => {
            resetForm()
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const sevaDateMap = sevadate => <SevaDate sevadate={sevadate} seva={seva}/>

    return (
        <div>
            <Header title={`${seva.name} Dates`} />
            {
                showAdd && <Modal close={closeModal} title={"Add Seva Date"}>
                    <div className="sevadates-create-modal">
                        <Form
                            config={getSevaDateFormConfig()}
                            name='sevadates-create'
                            onSubmit={handleSubmit}
                            persist
                        ></Form>
                    </div>
                </Modal>
            }
            <div>
                <div className="sevadates-add" onClick={handleAdd}>
                    <Icon name="add" color="#fff" size={8}></Icon>
                </div>
            </div>

            {sevaDates === undefined ?
                <PageLoader text="Loading sevas dates..." /> :
                <div>
                    <ListView
                        list={sevaDates}
                        map={sevaDateMap}
                    />
                </div>
            }
        </div>
    )

}

export default SevaDates