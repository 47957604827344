import './App.css'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Login from './pages/login'
import Home from './pages/homepage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import EditUserPhoto from './pages/edit-up'
import Users from './pages/users'
import Install from './pages/install'
import { GlobalProvider, useGlobal } from './context/global'
import UserAgent from './pages/test/user-agent'
import SignUp from './pages/signup'
import Applicants from './pages/applicants'
import { VolunteerProvider } from './context/volunteer'
import Volunteers from './pages/volunteers'
import Sevas from './pages/admin/sevas'
import SevaDates from './pages/admin/sevadates'
import Register from './pages/register'
import Protect from './protect'
import { useEffect } from 'react'
import { requestNotificationPermission } from './firebase/messaging'


const Device = () => {

  const { auth, deviceInfo, api } = useGlobal()
  const { os, browser, isMobileDevice, appInstalled } = deviceInfo
  useEffect(() => {
    requestNotificationPermission().then(fcmToken => {
      if (fcmToken) {
        const payload = auth.users.map(user => {
          return {
            userID: user.id,
            fcmToken, os, browser, isMobileDevice, appInstalled
          }
        })

        api.call('/update-device', payload).catch(err => {
          console.log(err)
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }, [])

  return <></>
}

function App({ storeData, apiClient }) {

  return (
    <div className="App">
      <>
        <GlobalProvider storeData={storeData} apiClient={apiClient}>
          <Device />
          <Router>
            <Routes>
              <Route path="/" exact element={<Login />}></Route>
              <Route path="/login" exact element={<Home />}></Route>
              <Route path="/edit-up" exact element={<EditUserPhoto />}></Route>
              <Route path="/home" exact element={<Home />}></Route>

              <Route path="/users" exact
                element={<Protect role={0}><Users /></Protect>}>
              </Route>

              <Route path="/signup" exact element={<SignUp />}></Route>

              <Route path="/applicants" exact
                element={
                  <Protect role={3}>
                    <VolunteerProvider>
                      <Applicants />
                    </VolunteerProvider>
                  </Protect>}>
              </Route>

              <Route path="/volunteers" exact
                element={
                  <Protect role={3}>
                    <VolunteerProvider>
                      <Volunteers />
                    </VolunteerProvider>
                  </Protect>}>
              </Route>

              <Route path="/install" exact element={<Install />}></Route>

              <Route path="/admin/seva" exact element={<Protect role={0}><Sevas /></Protect>}></Route>
              <Route path="/admin/seva-dates" exact element={<Protect role={0}><SevaDates /></Protect>}></Route>

              <Route path="/register" exact element={<Register />}></Route>

              <Route path="/test/user-agent" exact element={<UserAgent />}></Route>
            </Routes>
          </Router>
        </GlobalProvider>
      </>
      <ToastContainer />
    </div>
  )
}

export default App
