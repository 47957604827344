import "./index.css"
import Icon from "../../../../components/custom/icon"

const ConstructionHome = () => {
    return (
        <>
            <Icon name="engineering" size={[25, 10]} color="#555" />
            <div>
                <div className='appending-text flex-col flex-align-center'>
                    <div className="const-text">{`Under construction. Explore existing features by clicking the menu button.`}</div>
                </div>
            </div>
        </>
    )
}

export default ConstructionHome