import "./index.css"

import Search from "../../../components/custom/input/search"
import Drawer from "../../../components/holders/drawer"
import { useEffect, useState } from "react"
import Icon from "../../../components/custom/icon"

const ListView = ({ getDataKey, sortConfig, list, map, sortPrompt, searchPrompt, emptyMessage, expandPrompt }) => {

    const [expanded, setExpanded] = useState(false)
    const [showSort, setShowSort] = useState(false)
    const [processedList, setProcessedList] = useState(list)
    const [filterKey, setFilterKey] = useState('')
    const [sortConfigIndex, setSortConfigIndex] = useState(null)

    const getDefaultDataKey = key => isNaN(key) ? 'name' : 'phone'

    getDataKey = getDataKey || getDefaultDataKey
    const filterKeyExp = filterKey.toLowerCase().replace(/\s+/g, '')
    const dataKey = getDataKey(filterKeyExp)

    useEffect(() => {
        let filteredList = filterKey
            ? list.filter(a => a[dataKey].toLowerCase().includes(filterKeyExp))
            : list.map(a => a)

        if (sortConfigIndex !== null) {
            const sortCfg = sortConfig[sortConfigIndex]
            filteredList.sort((a, b) => {
                let aa = a[sortCfg.key] === null || a[sortCfg.key] === undefined ? '' : a[sortCfg.key]
                let bb = b[sortCfg.key] === null || b[sortCfg.key] === undefined ? '' : b[sortCfg.key]
                if (aa > bb) {
                    return sortCfg.asc ? 1 : -1
                } else if (aa < bb) {
                    return sortCfg.asc ? -1 : 1
                } else {
                    return 0
                }
            })
        }

        setProcessedList(filteredList)
    }, [sortConfigIndex, filterKey, list, sortConfig])

    const handleShowSort = () => {
        setShowSort(true)
    }

    const handleHideSort = () => {
        setShowSort(false)
    }

    const handleSort = i => {
        setShowSort(false)
        setSortConfigIndex(i)
    }

    return (
        <div className="lv-tab-main">

            {showSort && (
                <Drawer title={sortPrompt || "Choose sort order"} onClose={handleHideSort}>
                    <div>
                        {sortConfig.map((cfg, i) => (
                            <div className="lv-sort-main" onClick={() => handleSort(i)}>
                                <Icon name="tick" color={sortConfigIndex == i ? "#555" : "#fff"} className="lv-sort-tick" />
                                <div key={cfg.name}>{cfg.name}</div>
                            </div>
                        ))}
                    </div>
                </Drawer>
            )}

            <div className="lv-search-main">
                <Search width="82vw" delay={100} onSearch={setFilterKey} placeholder={searchPrompt || "Type to search..."} />
                <Icon className="lv-sort-icon" name="sort" color="#888" onClick={handleShowSort} />
            </div>

            <div className="lv-res-head">
                <div className="lv-res-count">{`Showing ${list.length} result${list.length !== 1 ? 's' : ''}`.trim()}</div>
                {sortConfigIndex !== null ? <div className="lv-res-count">
                    <span>{`Sort: ${sortConfig[sortConfigIndex].name}`}</span>
                    <div className="lv-sort-clear" onClick={() => { setSortConfigIndex(null) }}>Clear</div>
                </div> : null}
            </div>

            {list.length ?
                <div style={{ boxSizing: "border-box" }}>
                    {processedList.slice(0, expanded ? processedList.length : 20).map(map)}
                </div> :
                <div className="lv-no">{emptyMessage || "No results found"}</div>
            }

            {!expanded && list.length > 20 && (
                <div className="lv-more" onClick={() => setExpanded(true)}>
                    <div>{expandPrompt || "Show all"}</div>
                    <Icon name='arrow-drop-down' color='#eee' />
                </div>
            )}
        </div>
    )
}

export default ListView