import "./index.css"
import Header from "../../../components/custom/header"
import Icon from "../../../components/custom/icon"
import { useEffect, useState } from "react"
import Modal from "../../../components/holders/modal"
import _ from "../../../_"
import Form from "../../../components/custom/form"
import { useGlobal } from "../../../context/global"
import PageLoader from "../../../components/info/pageloader"
import ListView from "../../../components/holders/listView"
import Seva from "./seva"
import { getSevaFormConfig } from "../../../data/seva"

const Sevas = props => {

    const { api } = useGlobal()

    var [showAdd, setShowAdd] = useState()
    var [sevas, setSevas] = useState()

    const handleAdd = () => {
        setShowAdd(true)
    }

    const closeModal = () => {
        setShowAdd(false)
    }

    useEffect(() => {
        api.call("/get-seva").then(res => {
            setSevas(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleSubmit = (formData, _, resetForm) => {
        api.call("/add-seva", formData).then(res => {
            resetForm()
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }

    const sevaMap = seva => <Seva seva={seva} />

    return (
        <div>
            <Header title="Seva" />
            {
                showAdd && <Modal close={closeModal} title={"Create New Seva"}>
                    <div className="sevas-create-modal">
                        <Form
                            config={getSevaFormConfig()}
                            name='sevas-create'
                            onSubmit={handleSubmit}
                            persist
                        ></Form>
                    </div>
                </Modal>
            }
            <div>
                <div className="sevas-add" onClick={handleAdd}>
                    <Icon name="add" color="#fff" size={8} />
                </div>
            </div>

            {sevas === undefined ?
                <PageLoader text="Loading sevas..." /> :
                <div>
                    <ListView
                        list={sevas}
                        map={sevaMap}
                    />
                </div>
            }
        </div>
    )

}

export default Sevas