import { getMessaging, getToken, onMessage } from "firebase/messaging"
import firebaseApp from "./config"

const messaging = getMessaging(firebaseApp)
const vapidKey = 'BJsNwrdj2cbmpnnxTCsobX8gDaF3AnvQTyo-ubQfZDCGRr-aZhT9LSHWawnrAWCy2T5uJF1Lc2u3FYif7bLeYiE'

export const requestNotificationPermission = async () => {
    try {
        const token = await getToken(messaging, { vapidKey })
        if (token) {
            // console.log(token)
            return token
        } else {
            console.log("No registration token available.")
        }
    } catch (error) {
        console.log("Error getting token:", error)
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
